import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { INewBfmShopping } from '../../models/bfm/newBfm';
import { IBFMPayment } from '../../models/bfm/bfmpayment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})

export class BfmService {

  constructor(
    private http: HttpClient) {
  }


  getBuyForMeHistory(): Observable<any> {
    return this.http.get<any>(environment.api + 'bfm/history?isBasket=2');
  }

  getShoppingHistory(): Observable<any> {
    return this.http.get<any>(environment.api + 'bfm/history?isBasket=1');
  }

  buyForMeNew(payload: any): Observable<any> {
    return this.http.post<any>(environment.api + 'bfm/newProduct', payload);
  }

  buyForMeUpdate(payload: any): Observable<any> {
    return this.http.post<any>(environment.api + 'bfm/edit', payload);
  }

  deleteProductBfm(Id: number): Observable<any> {
    return this.http.post<any>(environment.api + 'bfm/delete/' + Id, {id: Id});
  }

  buyForMeNewShopping(payload: INewBfmShopping): Observable<any> {
    return this.http.post<any>(environment.api + 'bfm/newProduct', payload);
  }


  editBfm(payload: any): Observable<any> {
    return this.http.post<any>(environment.api + 'bfm/editamount', payload);
  }

  getBfmProductList(): Observable<any>{
    return this.http.get<any>(environment.api + 'bfm/getBfmProductList?isBasket=1');
  }

  getOnlyBfmProductList(): Observable<any>{
    return this.http.get<any>(environment.api + 'bfm/getBfmProductList');
  }

  deleteBfm(Id: number): Observable<any> {
    return this.http.delete<any>(environment.api + 'bfm/delete/' + Id);
  }

  paymentBfm(payload: IBFMPayment): Observable<any> {
    return this.http.post<any>(environment.api + 'bfm/bfmpayment', payload);
  }

  getCalculate(payload: any): Observable<any> {
    return this.http.post<any>(environment.api + 'bfm/calculate', payload);
  }

  sendTrStatus(payload: any): Observable<any>{
    return this.http.post<any>(environment.api + 'bfm/sendtrstatus', payload);
  }

}
